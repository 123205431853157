import Loadable from 'react-loadable';

import { CustomLoadableLoading } from '../../components/loading/loading.js';

const NewslettersPageAsync = Loadable({
	loader: () =>
		import(/* webpackChunkName: "newsletters-page" */ './newsletters.page.js'),
	modules: ['./newsletters.page.js'],
	webpack: () => [require.resolveWeak('./newsletters.page.js')],
	loading: CustomLoadableLoading,
});

export default NewslettersPageAsync;
