import Loadable from 'react-loadable';

import { CustomLoadableLoading } from '../../components/loading/loading.js';

const HomePageAsync = Loadable({
	loader: () => import(/* webpackChunkName: "tour-page" */'./tour.page.js'),
	modules: ['./tour.page.js'],
	webpack: () => [require.resolveWeak('./tour.page.js')],
	loading: CustomLoadableLoading,
});

export default HomePageAsync;
