import React, { Fragment, Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';
import { NavLink as Link } from 'react-router-dom';
// eslint-disable-next-line import/extensions
import { connect } from 'redux-zero/react';
import cn from 'classnames';

import LocaleSwitcher from './locale-switcher.js';
import { withLocaleContext } from '../locales/locale-context.js';
import { actions } from '../shared/store.js';
import { prefixPathWithLocale } from '../locales/locale-paths.js';

import logo from '../assets/images/home/amphibious_logo@3x.svg';
import secondLogo from '../assets/images/splash_tour_logo_color.png';
import secondLogoWhite from '../assets/images/home/Splashtours_logo.svg';

class Navbar extends Component {
	state = {
		isOpen: false,
	};

	toggleNav = () => {
		const { isOpen } = this.state;

		this.setState({ isOpen: !isOpen });
	};

	closeNav = () => {
		this.setState({ isOpen: false });
	};

	handleNavigationClick = () => {
		this.closeNav();
	};

	render() {
		const { isOpen } = this.state;
		const {
			localeContext: { locale },
			transparent,
		} = this.props;


		return (
			<div className={cn('site-navbar', { open: isOpen }, { transparentNav: transparent })}>
				<div className="container container-fluid">
					<div className="row">
						<div className="col-xs-6 col-md-3 start-xs middle-xs">
							<NavLogo />
							<SecondLogo
								white={transparent}
							/>
						</div>
						<div className="col-xs-6 col-md-9 end-xs middle-xs navbar-content">
							<NavList isOpen={isOpen}>
								<NavItem
									path={prefixPathWithLocale('/', locale)}
									exact
									label="navigation.homepage"
									handleNavigationClick={this.handleNavigationClick}
								/>
								<NavItem
									path={prefixPathWithLocale('/experiences', locale)}
									label="navigation.experiences"
									handleNavigationClick={this.handleNavigationClick}
								/>
								<NavItem
									path={prefixPathWithLocale('/tour', locale)}
									label="navigation.tour"
									handleNavigationClick={this.handleNavigationClick}
								/>

								<li className="nav-item line">
									<div className="line" />
								</li>

								<li className="nav-item">
									<LocaleSwitcher />
								</li>

								<NavbarButton
									path={prefixPathWithLocale('/checkout', locale)}
									handleNavigationClick={this.handleNavigationClick}
								>
									<FormattedMessage id="button.book-now" />
								</NavbarButton>

							</NavList>

							<NavMenuButton
								isOpen={isOpen}
								handleClick={this.toggleNav}
							/>

						</div>
					</div>
				</div>
			</div>
		);
	}
}

const NavbarButton = ({ path, children, handleNavigationClick }) => (
	<li className="nav-item" onClick={handleNavigationClick}>
		<Link to={path} className="navbar-button btn light">
			{children}
		</Link>
	</li>
);

const NavLogo = () => (
	<Link to="/">
		<img className="logo" src={logo} alt="Amphibious" />
	</Link>
);

const SecondLogo = ({ white }) => (
	<img className="second-logo" src={white ? secondLogoWhite : secondLogo} alt="Splash Tours" />
);

const NavList = ({ children, isOpen }) => (
	<nav className={`${cn({ open: isOpen })} nav-wrapper`}>
		<ul className="nav-list">{children}</ul>
	</nav>
);

const NavItem = ({
	path,
	label,
	externalLink,
	handleNavigationClick,
	exact,
}) => (
	<li className="nav-item">
		{!externalLink && (
			<Link
				to={path}
				exact={exact}
				onClick={handleNavigationClick}
				activeClassName="active"
			>
				<FormattedMessage id={label}>
					{txt => <Fragment>{txt}</Fragment>}
				</FormattedMessage>
			</Link>
		)}
		{externalLink && (
			<a
				href={path}
				target="_blank"
				rel="noopener noreferrer"
				onClick={handleNavigationClick}
			>
				<FormattedMessage id={label}>
					{txt => <Fragment>{txt}</Fragment>}
				</FormattedMessage>
			</a>
		)}
	</li>
);

const NavMenuButton = ({ isOpen, handleClick }) => (
	<button
		className={`${cn({ open: isOpen })} nav-menu-button`}
		onClick={handleClick}
	>
		<span />
		<span />
		<span />
		<span />
		<span />
		<span />
	</button>
);

const mapToProps = ({ mainData }) => ({
	mainData,
});
export default withRouter(withLocaleContext(
	connect(
		mapToProps,
		actions,
	)(Navbar),
));

export { Navbar };
