import React, {Component} from 'react';
import {Switch, withRouter} from 'react-router';
import {Route} from 'react-router-dom';
// eslint-disable-next-line import/extensions
import {connect} from 'redux-zero/react';
import {Helmet} from 'react-helmet';
import {IntlProvider, addLocaleData} from 'react-intl';
import en from 'react-intl/locale-data/en.js';
import pt from 'react-intl/locale-data/pt.js';
import fr from 'react-intl/locale-data/fr.js';
import de from 'react-intl/locale-data/de.js';
import es from 'react-intl/locale-data/es.js';

import {actions} from './shared/store.js';
import I18NLanguages from './i18n/index.js';
import routes from './routes/routes.js';
import NotImplemented from './components/not-implemented/not-implemented.js';
import Loading from './components/loading/loading.js';
import FancyOverlay from './components/fancy-layers/fancy-layers.component.js';
import LocaleProvider, {
	LocaleContext,
	RedirectIfWrongUrlLocale,
} from './locales/locale-context.js';

// Fix [mini-css-extract-plugin] order conflict warnings
import './layout/navbar.component.scss';
import './components/single-date-picker/single-date-picker.component.scss';

const intlMessages = I18NLanguages.reduce((result, language) => {
	// eslint-disable-next-line no-param-reassign
	result[language.code] = language.value;
	return result;
}, {});

addLocaleData([...en, ...pt, ...fr, ...de, ...es]);

class App extends Component {
	state = {
		isIE: false,
	};

	componentDidMount() {
		const isIE = /*@cc_on!@*/false || !!document.documentMode;
		this.setState({isIE});
		const {setFirstTimeAnimation, firstTimeAnimation} = this.props;
		if (!firstTimeAnimation) {
			setFirstTimeAnimation(true);
		}
	}

	componentDidUpdate(prevProps) {
		const {location} = this.props;

		if (location !== prevProps.location) {
			window.scrollTo({
				top: 0,
			});
		}
	}

	render() {
		const {isIE} = this.state;
		const {isLoading, firstTimeAnimation} = this.props;

		return (
			<div className="app-shell">
				<LocaleProvider>
					<LocaleContext.Consumer>
						{({locale}) => (
							<IntlProvider
								locale={locale.value}
								messages={intlMessages[locale.value]}
							>
								<React.Fragment>
									<Helmet
										defaultTitle="Amphibious"
										titleTemplate="Amphibious - %s"
									/>

									<RedirectIfWrongUrlLocale/>

									{!isIE && isLoading && <Loading/>}
									{!isIE && firstTimeAnimation && <FancyOverlay/>}
									<Switch>
										{routes.map(route => (
											<Route
												key={route.path}
												path={route.path}
												exact={route.exact}
												component={route.component}
											/>
										))}

										<Route component={NotImplemented}/>
									</Switch>
								</React.Fragment>
							</IntlProvider>
						)}
					</LocaleContext.Consumer>
				</LocaleProvider>
			</div>
		);
	}
}

const mapToProps = ({isLoading, loaded, firstTimeAnimation}) => ({
	isLoading,
	loaded,
	firstTimeAnimation,
});
export default withRouter(
	connect(
		mapToProps,
		actions,
	)(App),
);
