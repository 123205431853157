import Loadable from 'react-loadable';

import { CustomLoadableLoading } from '../../components/loading/loading.js';

const HomePageAsync = Loadable({
	loader: () => import(/* webpackChunkName: "faqs-page" */'./faqs.page.js'),
	modules: ['./faqs.page.js'],
	webpack: () => [require.resolveWeak('./faqs.page.js')],
	loading: CustomLoadableLoading,
});

export default HomePageAsync;
