import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { addLanguageToPath } from '../../shared/navigation-links.js';

import Navbar from '../../layout/navbar.component.js';

import './not-implemented.scss';

const NotImplemented = ({ title }) => (
	<Fragment>
		<Navbar />
		<div className="not-implemented-wrapper">
			<div className="not-implemented-content">
				{title && <h1>{title}</h1>}
				<div className="center-xs">
					<p className="title"><FormattedMessage id="not-implemented.title" /></p>
					<p className="message"><FormattedMessage id="not-implemented.message" /></p>
					<Link to={addLanguageToPath('/')}>
						<FormattedMessage id="not-implemented.button_label" />
					</Link>
				</div>
			</div>
		</div>
	</Fragment>
);

export default injectIntl(NotImplemented);
