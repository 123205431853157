const defaultSupportedLocale = {
	value: 'pt',
	label: 'Português',
};

const supportedLocales = [
	defaultSupportedLocale,
	{
		value: 'en',
		label: 'English',
	},
	{
		value: 'fr',
		label: 'Français',
	},
	{
		value: 'de',
		label: 'Deutsch',
	},
	{
		value: 'es',
		label: 'Español',
	},
];

export { defaultSupportedLocale, supportedLocales };
