import en from './en.json';
import pt from './pt.json';
import fr from './fr.json';
import de from './de.json';
import es from './es.json';

const Languages = [
	{ code: 'en', label: 'English', value: en },
	{ code: 'pt', label: 'Português', value: pt },
	{ code: 'fr', label: 'Français', value: fr },
	{ code: 'de', label: 'Deutsch', value: de },
	{ code: 'es', label: 'Español', value: es },
];

export default Languages;
