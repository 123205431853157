import Loadable from 'react-loadable';

import { CustomLoadableLoading } from '../../components/loading/loading.js';

const ProjectSheetPageAsync = Loadable({
	loader: () => import(/* webpackChunkName: "project-sheet-page" */'./project-sheet.page.js'),
	modules: ['./project-sheet.page.js'],
	webpack: () => [require.resolveWeak('./project-sheet.page.js')],
	loading: CustomLoadableLoading,
});

export default ProjectSheetPageAsync;
