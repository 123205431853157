import React from 'react';
import { connect } from 'redux-zero/react';

import { actions } from '../../shared/store.js';

import bus from '../../assets/images/loading/bus-blue.svg';

import './loading.scss';

const Loading = () => (
	<div className="loading">
		<div className="loader">
			<div className="bus">
				<img src={bus} alt="" />
			</div>
			<div className="wave">
				<div className="wave-container">
					<div className="sliding-background" />
				</div>
			</div>
		</div>
	</div>
);

const mapToProps = ({ language, seeker, isLoading }) => ({
	language,
	seeker,
	isLoading,
});

const CustomLoadableLoading = connect(
	mapToProps,
	actions,
)(
	class CustomLoadableLoadingInternal extends React.Component {
		constructor(props) {
			super(props);

			props.setLoading(true);
		}

		render() {
			return null;
		}
	},
);

export default Loading;

export { CustomLoadableLoading };
