import React, { Component } from 'react';
import { matchPath, withRouter, Redirect } from 'react-router';
// eslint-disable-next-line import/extensions
import { connect } from 'redux-zero/react';
import Cookies from 'js-cookie';
import trim from 'lodash.trim';

import {
	supportedLocales as supportedLanguages,
	defaultSupportedLocale as defaultSupportedLanguage,
} from './supported-locales.js';

const ONE_YEAR_EXPIRATION = 365;

const LocaleContext = React.createContext();

function getLocaleByValueOrDefault(
	value,
	supportedLocales = supportedLanguages,
	defaultLocale = defaultSupportedLanguage,
) {
	return (
		supportedLocales.find(supportedLocale => supportedLocale.value === value)
		|| defaultLocale
	);
}

function matchLocalizedPath(pathname, supportedLocales = supportedLanguages) {
	return matchPath(pathname, {
		path: supportedLocales.map(
			supportedlocale => `/:locale(${supportedlocale.value})/*`,
		),
	});
}

class LocaleProvider extends Component {
	static defaultProps = {
		cookieName: '_locale',
		defaultLocale: defaultSupportedLanguage,
		supportedLocales: supportedLanguages,
	};

	constructor(props) {
		super(props);

		const {
			cookieName, defaultLocale, supportedLocales, language,
		} = this.props;

		this.state = {
			locale: getLocaleByValueOrDefault(
				Cookies.get(cookieName),
				supportedLocales,
				language || defaultLocale,
			),
			defaultLocale,
			supportedLocales,
		};
	}

	changeLocale = (newLocale) => {
		const {
			cookieName,
			location: { pathname },
			history,
			supportedLocales,
			defaultLocale,
		} = this.props;
		const { locale } = this.state;

		const match = matchLocalizedPath(pathname, supportedLocales);

		if (newLocale.value === locale.value) return;

		if (newLocale.value === defaultLocale.value && !!match) {
			// remove the language from the url if exists

			const newLocation = match.url.replace(`/${match.params.locale}/`, '/');
			history.push(newLocation);
		} else if (!match) {
			// add the language to the url

			const newLocation = `/${newLocale.value}${pathname}`;
			history.push(newLocation);
		}

		this.setState({
			locale: newLocale,
		});

		Cookies.set(cookieName, trim(newLocale.value, '/'), {
			expires: ONE_YEAR_EXPIRATION,
		});
	};

	render() {
		const { children } = this.props;

		const contextValue = {
			...this.state,
			changeLocale: this.changeLocale,
		};

		return (
			<LocaleContext.Provider value={contextValue}>
				{children}
			</LocaleContext.Provider>
		);
	}
}

function withLocaleContext(InnerComponent) {
	return props => (
		<LocaleContext.Consumer>
			{localeContext => (
				<InnerComponent {...props} localeContext={localeContext} />
			)}
		</LocaleContext.Consumer>
	);
}

function removeLastSlash(path) {
	if (path !== '/' && path.endsWith('/')) {
		return path.substring(0, path.length - 1);
	}

	return path;
}

const RedirectIfWrongUrlLocale = withRouter(
	withLocaleContext(
		({
			location,
			localeContext: { locale, supportedLocales, defaultLocale },
		}) => {
			const match = matchLocalizedPath(location.pathname, supportedLocales);

			// A supported locale (not the default) is present in the URL
			if (match && match.params.locale) {
				if (locale.value !== match.params.locale) {
					// add locale
					let newPathname = match.params.locale !== defaultLocale.value ? '' : `/${match.params.locale}`;
					// build a clear array with the params elements without locale
					const finalParams = [];
					Object.keys(match.params).forEach(key => {
						if(key !== 'locale' && match.params[key]){
							finalParams.push(match.params[key]);
						}
					});
					// join
					newPathname = `${newPathname}/${finalParams.join('/')}`;
					return <Redirect to={removeLastSlash(newPathname)} />;
				}
			} else if (locale.value !== defaultLocale.value) {
				if (location.pathname.startsWith(`/${defaultLocale.value}/`)) {
					// replace the locale on the URL
					const newPathname = location.pathname.replace(
						`/${defaultLocale.value}/`,
						`/${locale.value}/`,
					);
					return <Redirect to={removeLastSlash(newPathname)} />;
				}

				// add the locale to the URL
				const newPathname = `/${locale.value + location.pathname}`;
				return <Redirect to={newPathname} />;
			} else if (locale.value === defaultLocale.value) {
				if (location.pathname.startsWith(`/${defaultLocale.value}/`)) {
					// remove the locale from the URL
					const newPathname = match.url.replace(
						`/${defaultLocale.value}/`,
						'/',
					);
					return <Redirect to={removeLastSlash(newPathname)} />;
				}
			}

			// if (location.pathname !== '/' && location.pathname.endsWith('/')) {
			// 	return <Redirect to={removeLastSlash(location.pathname)} />;
			// }

			return null;
		},
	),
);

const mapToProps = ({ language }) => ({
	language,
});

export default connect(mapToProps)(withRouter(LocaleProvider));

export { LocaleContext, withLocaleContext, RedirectIfWrongUrlLocale };
