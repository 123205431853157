import localizeRoutes from './localize-routes.js';

import HomePageAsync from '../features/home/home.page.async.js';
import ExperiencesPageAsync from '../features/experiences/experiences.page.async.js';
import ExperienceDetailsPageAsync
	from '../features/experiences/details/experience-details.page.async.js';
import TourPageAsync from '../features/tour/tour.page.async.js';
import CheckoutPageAsync from '../features/checkout/checkout.page.async.js';
import FaqsPageAsync from '../features/frequently-questions/faqs.page.async.js';
import NewslettersPageAsync from '../features/newsletters/newsletters.page.async.js';
import MyBookingPageAsync from '../features/my-booking/my-booking.page.async.js';
import ProjectSheetPageAsync from '../features/project-sheet/project-sheet.page.async.js';
import InternalizationProjectSheetPageAsync
	from '../features/internationalization-project-sheet/project-sheet.page.async.js';

const routes = [
	{
		path: '/',
		exact: true,
		component: HomePageAsync,
	},
	{
		path: '/experiences',
		exact: true,
		component: ExperiencesPageAsync,
	},
	{
		path: '/experiences/:slug',
		component: ExperienceDetailsPageAsync,
	},
	{
		path: '/tour',
		component: TourPageAsync,
	},
	{
		path: '/checkout',
		component: CheckoutPageAsync,
	},
	{
		path: '/faqs',
		component: FaqsPageAsync,
	},
	{
		path: '/newsletters',
		component: NewslettersPageAsync,
	},
	{
		path: '/check-my-booking',
		exact: true,
		component: MyBookingPageAsync,
	},
	{
		path: '/check-my-booking/:orderReference',
		component: MyBookingPageAsync,
	},
	{
		path: '/project-sheet',
		component: ProjectSheetPageAsync,
	},
	{
		path: '/internationalization-project-sheet',
		component: InternalizationProjectSheetPageAsync,
	},
];

export default localizeRoutes(routes);
