import Loadable from 'react-loadable';

import { CustomLoadableLoading } from '../../../components/loading/loading.js';

const ExperienceDetailsPageAsync = Loadable({
	loader: () => import(/* webpackChunkName: "experience-details-page" */'./experience-details.page.js'),
	modules: ['./experience-details.page.js'],
	webpack: () => [require.resolveWeak('./experience-details.page.js')],
	loading: CustomLoadableLoading,
});

export default ExperienceDetailsPageAsync;
