import React from 'react';
import cn from 'classnames';

import { withLocaleContext } from '../locales/locale-context.js';

class LocaleSwitcher extends React.Component {
	handleLocaleChange = (selectedLocale) => {
		const {
			localeContext: { changeLocale },
		} = this.props;

		changeLocale(selectedLocale);
	};

	render() {
		const {
			localeContext: { locale, supportedLocales },
		} = this.props;

		return (
			<div className="i18n">
				{supportedLocales.map(supportedLocale => (
					<div key={supportedLocale.value} className={cn('lang', { active: locale.value === supportedLocale.value })}>
						{locale.value === supportedLocale.value ? (
							<span
								className={`${cn({
									active: locale.value === supportedLocale.value,
								})} `}
							>
								{supportedLocale.value.toUpperCase()}
							</span>
						) : (
							<span onClick={() => this.handleLocaleChange(supportedLocale)}>
								{supportedLocale.value.toUpperCase()}
							</span>
						)}
					</div>
				))}
			</div>
		);
	}
}

export default withLocaleContext(LocaleSwitcher);
