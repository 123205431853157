import { matchPath } from 'react-router';

function addLanguageToPath(path, pathname) {
	const match = matchPath(pathname, {
		path: '/:lang(pt)/*',
	});

	if (!match) return path;

	return `/${match.params.lang}${path}`;
}

export { addLanguageToPath };
