import React from 'react';

import './fancy-layers.scss';
import { connect } from 'redux-zero/react';
import { actions } from '../../shared/store.js';

//
// these easing functions are based on the code of glsl-easing module.
// https://github.com/glslify/glsl-easings
//

const ease = {
	cubicOut: (t) => {
		const f = t - 1.0;
		return f * f * f + 1.0;
	},
	cubicInOut: t => (t < 0.5
		? 4.0 * t * t * t
		: 0.5 * Math.pow(2.0 * t - 2.0, 3.0) + 1.0),
};

class Overlay extends React.Component {
	static defaultProps = {
		numPoints: 2,
		duration: 600,
		delayPointsArray: [0, 0],
		delayPointsMax: 0,
		delayPerPath: 200,
		numberOfLayers: 3,
	};

	state = {
		currentTime: Date.now(),
	};

	constructor(props) {
		super(props);

		this.timeStart = Date.now();
	}

	componentDidMount() {
		this.renderLoop();
	}

	updatePath(time) {
		const {
			numPoints, duration, delayPointsArray,
		} = this.props;

		const points = [];
		for (let i = 0; i < numPoints; i += 1) {
			const thisEase = (i === 1) ? ease.cubicInOut : ease.cubicOut;
			points[i] = thisEase(Math.min(Math.max(time - delayPointsArray[i], 0) / duration, 1)) * 100;
		}

		let str = '';
		str += `M 0 ${points[0]} `;
		for (let i = 0; i < numPoints - 1; i++) {
			const p = (i + 1) / (numPoints - 1) * 100;
			const cp = p - (1 / (numPoints - 1) * 100) / 2;
			str += `C ${cp} ${points[i]} ${cp} ${points[i + 1]} ${p} ${points[i + 1]} `;
		}
		str += 'V 100 H 0';
		return str;
	}

	renderLoop = () => {
		const {
			delayPerPath, delayPointsMax, duration, numberOfLayers, isFirstAnimation, setFirstTimeAnimation,
		} = this.props;

		if (Date.now() - this.timeStart <= duration + delayPerPath * (numberOfLayers - 1) + delayPointsMax) {
			requestAnimationFrame(() => {
				this.setState({ currentTime: Date.now() });
				this.renderLoop();
			});
		} else {
			if (isFirstAnimation) {
				setFirstTimeAnimation(false);
			}
			clearInterval(this.interval);
		}
	};

	render() {
		const { delayPerPath, numberOfLayers } = this.props;

		return (
			<svg className="shape-overlays" viewBox="0 0 100 100" preserveAspectRatio="none">
				<path d={this.updatePath(Date.now() - (this.timeStart + delayPerPath * (numberOfLayers - 1)))} className="shape-overlays__path" />
				<path d={this.updatePath(Date.now() - (this.timeStart + delayPerPath * (numberOfLayers - 2)))} className="shape-overlays__path" />
				<path d={this.updatePath(Date.now() - (this.timeStart + delayPerPath * (numberOfLayers - 3)))} className="shape-overlays__path" />
			</svg>
		);
	}
}

const mapToProps = ({
	isFirstAnimation,
}) => ({
	isFirstAnimation,
});


export default connect(
	mapToProps,
	actions,
)(Overlay);
