import Loadable from 'react-loadable';

import { CustomLoadableLoading } from '../../components/loading/loading.js';

const MyBookingPageAsync = Loadable({
	loader: () => import(/* webpackChunkName: "my-booking-page" */'./my-booking.page.js'),
	modules: ['./my-booking.page.js'],
	webpack: () => [require.resolveWeak('./my-booking.page.js')],
	loading: CustomLoadableLoading,
});

export default MyBookingPageAsync;
