import createStore from 'redux-zero';

const initialStore = {
	isLoading: false,
	firstTimeAnimation: true,
	loaded: false,
	homePageData: null,
	homePageExperiences: null,
	experiencesPageData: null,
	experiencesCategoryList: null,
	experiencesMostPopular: null,
	experiencesCategories: null,
	experiencesRangePrice: null,
	tourPageData: null,
	contentData: null,
	faqs: null,
	newsletters: null,
	theRoute: null,
	augmentedReality: null,
	experienceDetails: null,
	amphibiousTourDetails: null,
	checkoutFilters: null,
	projectSheetPageData: null,
	checkoutExperiences: null,
};

function initialState({ language, languages, ...props }) {
	return {
		languages,
		language,
		...initialStore,
		...props,
	};
}

const actions = () => ({
	setLoading: (state, value) => () => ({ isLoading: value }),
	setLoaded: (state, value) => ({
		loaded: value,
	}),
	setFirstTimeAnimation: (state, value) => ({
		firstTimeAnimation: value,
	}),
	setContentData: (state, value) => ({
		contentData: value,
	}),
	setHomePageData: (state, value) => ({
		homePageData: value,
	}),
	setHomePageExperiences: (state, value) => ({
		homePageExperiences: value,
	}),
	setSearchExperiences: (state, value) => ({
		searchExperiences: value,
	}),
	setProjectSheetPageData: (state, value) => ({
		projectSheetPageData: value,
	}),
	setExperiencesPageData: (state, value) => ({
		experiencesPageData: value,
	}),
	setExperiencesCategoryList: (state, value) => ({
		experiencesCategoryList: value,
	}),
	setExperiencesMostPopular: (state, value) => ({
		experiencesMostPopular: value,
	}),
	setExperiencesCategories: (state, value) => ({
		experiencesCategories: value,
	}),
	setExperiencesRangePrice: (state, value) => ({
		experiencesRangePrice: value,
	}),
	setTourPageData: (state, value) => ({
		tourPageData: value,
	}),
	setFaqs: (state, value) => ({
		faqs: value,
	}),
	setNewsletters: (state, value) => ({
		newsletters: value,
	}),
	setTheRoute: (state, value) => ({
		theRoute: value,
	}),
	setAugmentedReality: (state, value) => ({
		augmentedReality: value,
	}),
	setExperienceDetails: (state, value) => ({
		experienceDetails: value,
	}),
	setAmphibiousTourDetails: (state, value) => ({
		amphibiousTourDetails: value,
	}),
	setCheckoutFilters: (state, value) => ({
		checkoutFilters: value,
	}),
	setCheckoutExperiences: (state, value) => ({
		checkoutExperiences: value,
	}),
	setCheckoutExperience: (state, value) => ({
		checkoutExperience: value,
	}),
});

let store = null;

function initializeStoreClient(state) {
	store = createStore(initialState(state));
	return store;
}

function initializeStoreServer(state) {
	store = createStore(initialState(state));
	return store;
}

export {
	initializeStoreClient, initializeStoreServer, store, actions,
};
