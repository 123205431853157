import Loadable from 'react-loadable';

import { CustomLoadableLoading } from '../../components/loading/loading.js';

const ExperiencesPageAsync = Loadable({
	loader: () => import(/* webpackChunkName: "experiences-page" */'./experiences.page.js'),
	modules: ['./experiences.page.js'],
	webpack: () => [require.resolveWeak('./experiences.page.js')],
	loading: CustomLoadableLoading,
});

export default ExperiencesPageAsync;
